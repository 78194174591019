import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import data from '../data.json';
import data_v from '../data_v.json';
import './Main.css';

export default function Main() {
    const [menu, setMenu] = useState(false);
    return (
        <main>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FF6347" fill-opacity="1" d="M0,256L40,256C80,256,160,256,240,240C320,224,400,192,480,197.3C560,203,640,245,720,261.3C800,277,880,267,960,250.7C1040,235,1120,213,1200,224C1280,235,1360,277,1400,298.7L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>
            <header>
                <nav id='Nav'>
                    <div className='icon-title-box'>
                       <img id='Icon' src='https://res.cloudinary.com/prohibitedview/image/upload/v1705816054/Prohibited%20View/prohibitedviewicon.png' alt='Main_Icon' />
                       <h1 id='Title'>Prohibited View</h1>
                    </div>
                    <div className='content-links-box' style={{display: `${menu ? 'flex' : ''}`}}>
                       <Link to='https://twitter.com/ProhibitedView'><i className="fa-brands fa-x-twitter"></i></Link>
                       <Link to='https://www.instagram.com/prohibitedview/'><i className="fa-brands fa-instagram"></i></Link>
                       <Link to='https://www.youtube.com/@ProhibitedView'><i className="fa-brands fa-youtube"></i></Link>
                       <Link to=''><i className="fa-solid fa-infinity"></i></Link>
                    </div>
                    <div className='content-links-menu'>
                        <Link onClick={() => setMenu(!menu)}><i className="fa-solid fa-bars"></i></Link>
                    </div>
                </nav>
            </header>
            {/* Zero */}
            <section id='Zero'>
                <div className='languages'>
                    <div className='btn Chinese' onClick={() => alert('Chinese')}>Chinese</div>
                    <div className='btn Thailand' onClick={() => alert('Thailand')}>Thailand</div>
                    <div className='btn Korean' onClick={() => alert('Korean')}>Korean</div>
                </div>
            </section>

            {/* First */}
            <section id='First'>
                <article className='photos'>
                <h1>Photos</h1>
                <div className='photo-grid'>
                {data && data.map(item => 
                <div key={item.key} className='photo-item' style={{backgroundImage: `url(${item.image})`}}>
                    <h2>{item.name}</h2>
                </div>)}
                </div>
                </article>
                <article className='videos'>
                <h1>Videos</h1>
                <div className='video-grid'>
                {data_v && data_v.map(item => 
                <iframe key={item.key} className='video-item' poster={item.poster} src={item.url} title={item.title}></iframe>)}
                </div>
                </article>
            </section>

            {/* Second */}
            <section id='Second'>
                <article className='content-3'>
                </article>
            </section>
            <footer>
                <div id='Footer'>

                </div>
            </footer>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FF6347" fill-opacity="1" d="M0,96L48,90.7C96,85,192,75,288,69.3C384,64,480,64,576,80C672,96,768,128,864,128C960,128,1056,96,1152,80C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        </main>
    )
}